import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import '../styles/AppHeader.css';
import { Link } from 'react-router-dom';
import ModalComponent from './ModalComponent';
import movaexImage from '../assets/movaex__header__logo.png';

export default function AppHeader() {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleGetStartedClick = () => {
    const isSmallDevice = window.innerWidth <= 768;

    if (!isSmallDevice) {
      setModalOpen(true);
    } else {
      window.open('https://app.movaex.com');
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  let maxWidth = '30%';

  if (window.innerWidth > 768 && window.innerWidth <= 1024) {
    maxWidth = '50%';
  }

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    content: {
      maxWidth,
      margin: 'auto',
    },
  };

  const items = [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'FAQ',
      url: '/faq',
    },
    {
      label: 'About ',
      url: '/about',
    },

    {
      label: 'About ',
      url: '/about',
    },

    {
      label: 'Career ',
      url: '/career',
    },
  ];

  const start = (
    <Link to='/'>
      <div className='movaex__image'>
        <img src={movaexImage} alt='Movaex logo' className='___header___logo' />
      </div>
    </Link>
  );
  const endTemplate = (
    <div>
      <button
        type='button'
        className='buttonStylef'
        onClick={handleGetStartedClick}
      >
        {' '}
        Open App
      </button>

      <ModalComponent
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        iframeSrc='https://app.movaex.com'
        style={modalStyle}
      />
    </div>
  );

  return (
    <div className='w-full  mb-1 md:w-10 m-auto md:mt-2 md:mb-2  '>
      <Menubar
        model={items}
        start={start}
        end={endTemplate}
        breakpoint='960px'
        className='menubarStyle'
      />
    </div>
  );
}
