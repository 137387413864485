import { Divider } from 'primereact/divider';
import '../styles/career.css';
import Herosection from '../components/shareCoponent/heroSection';

export default function Career() {
  const data = [
    {
      title: 'Logistic Manager (2-4 years experience)',
      location: 'Lagos ',
    },
    {
      title: 'Accountant  (1-2 years experience)',
      location: 'Lagos',
    },
    {
      title: 'Delivery Officer ( Dispatcher rider )  ',
      location: 'Port Harcourt, Enugu and Lagos',
    },
    {
      title: 'Social media  (intern)',
      location: ' Lagos',
    },
    {
      title: 'Front desk Officer (intern)',
      location: 'Lagos',
    },

    // {
    //   title: 'Strategic Initiatives Officer',
    //   location: 'Port Harcourt',
    // },
  ];
  return (
    <div className='career___main'>
      {/* hero section component starts here */}
      <Herosection title='Join The Team' />
      {/* hero section component ends here */}

      <div className='w-11 md:w-11 lg:w-11 xl:w-10 m-auto'>
        <div className='text-center'>
          <h4>Available Jobs</h4>
          <p className='title___p'>
            {' '}
            We are building an extraordinary team of strong minds driven by the
            hunger to co-create solutions. Creating a new kind of logistics
            platform requires top talent, so we are committed to ensuring only
            the best are allowed in our community to sustain the nurturing
            culture of our community.
          </p>
        </div>
        <Divider />

        <section className='career___section mt-5'>
          <div className='grid'>
            {data.map((item, index) => (
              <div className='col-12 md:col-6 lg:col-4 xl:col-4 ' key={index}>
                <div className='career__card'>
                  <p className='__career__paragraph__p  mb-0 capitalize '>
                    {item.title}
                  </p>
                  <small className='___small mb-0'>{item.location}</small>

                  <h6 className='__career__paragraph__apply '>
                    <a href='mailto:hiring@movaex.com' className='____ha'>
                      {' '}
                      Apply Now
                    </a>
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
